import React from "react";
import styles from "./styles.module.scss";

const Ad = ({ ad }) => {
	const hasShadow = ad.title || ad.subtitle;
	return (
		<div className={styles.item}>
			<a
				href={ad.uri}
				target="_blank"
				rel="noreferrer"
				className={styles.itemWrapper}
			>
				<div
					className={`${styles.imgWrapper} ${
						hasShadow && styles.withShadow
					}`}
				>
					{ad.imagen && ad.imagen.url && (
						<img src={ad.imagen.url} alt={ad.descuento} />
					)}
					{ad.etiqueta && (
						<span className={styles.highlight}>{ad.etiqueta}</span>
					)}
					<div className={styles.place}>
						{ad.title && <h5>{ad.title}</h5>}
						{ad.subtitle && <p>{ad.subtitle}</p>}
					</div>
				</div>
				<div className={styles.texto}>
					<div className={styles.contents}>{ad.texto}</div>
					<div className={styles.link}>
						<span>
							Ver más
							<img src="/img/white-arrow.svg" alt="Ver más" />
						</span>
					</div>
				</div>
			</a>
		</div>
	);
};

export default Ad;
