import React from "react";
import { CustomLinkExternal } from "../../../assets/styles/globalCommon";
import { extractImgSrcFromString } from "../../../utils/utils";
import DOMPurify from "dompurify";
import { NewsItemWrapper } from "./styled";

const NewsItem = (props) => {
	const { image = null, date, title, url, description = null } = props;
	const newsDate = new Date(date);
	const newsDateMonth = new Intl.DateTimeFormat("es-Es", {
		month: "long",
	}).format(newsDate);
	const newsDateDay = new Intl.DateTimeFormat("es-Es", {
		day: "2-digit",
	}).format(newsDate);

	const renderImg = () => {
		if (image !== null) {
			return (
				<div className="img">
					<img src={image} alt={title} />
				</div>
			);
		} else if (description !== null) {
			const imgSrc = extractImgSrcFromString(description);
			if (imgSrc) {
				return (
					<div
						className="img"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(imgSrc[0]),
						}}
					/>
				);
			}
		}
	};

	return (
		<NewsItemWrapper>
			<a href={url} target="_blank" rel="noreferrer" className="imgWrapper">
				{renderImg()}
				<div className="gradient">
					<img
						src="/img/logo-lugares-de-nieve.png"
						alt="Lugares de Nieve"
					/>
				</div>
			</a>
			<div className="text">
				<small>
					{newsDateMonth}, {newsDateDay}
				</small>
				<h5>{title}</h5>
				<CustomLinkExternal
					href={url}
					target="_blank"
					rel="noreferrer"
					className="ghost withUnderline"
				>
					<p>Leer más</p>
				</CustomLinkExternal>
			</div>
		</NewsItemWrapper>
	);
};

export default NewsItem;
