import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const SliderWrapper = styled.section`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 30px;
	position: relative;
`;

export const SliderTitle = styled.div`
	order: 0;
	max-width: 50%;
	h3 {
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00};
		margin-bottom: 10px;
	}
	p {
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00};
		margin: 0;
	}
	@media ${mediaQueryMax.lg} {
		flex: 0 0 100%;
		max-width: 100%;
	}
`;

export const SliderBtns = styled.div`
	order: 1;
	max-width: 50%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 30px;
	.arrowsWrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		.arrowBtn {
			overflow: hidden;
			border-radius: 5px;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.gray90};
			transition: background-color 0.3s ease-in-out;
			cursor: pointer;
			span.icon {
				width: 20px;
				svg {
					path {
						fill: ${colors.white};
					}
				}
			}
			&:hover {
				background-color: ${colors.main};
			}
		}
	}
	@media ${mediaQueryMax.lg} {
		order: 2;
		justify-content: space-between;
		flex: 0 0 100%;
		max-width: 100%;
		.arrowsWrapper {
			margin-left: auto;
		}
	}
	@media ${mediaQueryMax.xs2} {
		.arrowsWrapper {
			display: none;
		}
	}
`;

export const SliderItems = styled.div`
	order: 2;
	flex: 0 0 100%;
	max-width: 100%;
	.swiper {
		overflow: visible;
		.swiper-wrapper {
			.swiper-slide {
				height: auto;
			}
		}
	}
	@media ${mediaQueryMax.lg} {
		order: 1;
	}
`;
