import React from "react";
import styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";

const Snowgod = ({ imagen, nombre, link }) => {
	return (
		<Link
			to={link.url}
			className={styles.container}
			style={{ backgroundImage: `url('${imagen}')` }}
		>
			<div className={styles.info}>
				<h4 className={styles.nombre}>{nombre}</h4>
				<div className={styles.centro}>
					<span className="lineLink ltblue">
						<u>{link.text}</u>
					</span>
				</div>
			</div>
		</Link>
	);
};

export default Snowgod;
