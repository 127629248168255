import styled from "styled-components";
import { colors } from "../../../assets/styles/vars";

export const NewsItemWrapper = styled.div`
	background-color: ${colors.gray40};
	height: 100%;
	.imgWrapper {
		aspect-ratio: 16/9;
		position: relative;
		overflow: hidden;
		display: block;
		.img {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 0;
			}
		}
		.gradient {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			background-color: rgba(0, 0, 0, 0.25);
			display: flex;
			flex-wrap: wrap;
			align-items: end;
			padding: 10px;
			box-sizing: border-box;
		}
	}
	.text {
		padding: 15px 25px;
		overflow: hidden;

		small {
			color: ${colors.gray};
			font-size: 13px;
			line-height: 23px;
			margin-bottom: 5px;
			text-transform: capitalize;
		}

		h5 {
			font-weight: 500;
			color: ${colors.black00};
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 10px;
		}

		a {
			float: right;
		}
	}
`;
