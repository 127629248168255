import React, { useRef } from "react";
import { SliderWrapper, SliderTitle, SliderBtns, SliderItems } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import Ad from "../../Ad";
import {
	CustomLink,
	CustomLinkExternal,
} from "../../../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";
import Snowgod from "../../Snowgod";
import NewsItem from "../NewsItem";

const Slider = (props) => {
	const {
		layout,
		type,
		items,
		title,
		text,
		buttonText,
		buttonUrl,
		isExternal = false,
	} = props;
	const sliderRef = useRef();

	const sliderChange = (direction) => {
		direction === "next"
			? sliderRef?.current?.swiper.slideNext()
			: sliderRef?.current?.swiper.slidePrev();
	};

	const renderItem = (item) => {
		switch (type) {
			case "ads":
				return <Ad ad={item} />;
			case "snowgod":
				return (
					<Snowgod
						imagen={item.image}
						nombre={item.title}
						link={{
							text: item.link.text,
							url: item.link.url,
						}}
					/>
				);
			case "news":
				return (
					<NewsItem
						image={item.image}
						date={item.pubDate}
						title={item.title}
						url={item.link}
						description={item.description}
					/>
				);
			default:
				return null;
		}
	};

	const renderButton = () => {
		if (buttonText && buttonUrl) {
			if (isExternal) {
				return (
					<CustomLinkExternal
						href={buttonUrl}
						target="_blank"
						rel="noreferrer"
						className={`ghost withUnderline ${
							layout === "dark" && "secondary"
						}`}
					>
						<p>{buttonText}</p>
					</CustomLinkExternal>
				);
			} else {
				return (
					<CustomLink
						to={buttonUrl}
						className={`ghost withUnderline ${
							layout === "dark" && "secondary"
						}`}
					>
						<p>{buttonText}</p>
					</CustomLink>
				);
			}
		}
	};

	return (
		<SliderWrapper>
			<SliderTitle layout={layout}>
				{title && <h3>{title}</h3>}
				{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
			</SliderTitle>
			<SliderBtns>
				{renderButton()}
				<div className="arrowsWrapper">
					<button
						className="arrowBtn"
						onClick={() => sliderChange("prev")}
					>
						<ReactSVG
							src="/img/icons/icon-arrow-left.svg"
							wrapper="span"
							className="icon"
						/>
					</button>
					<button
						className="arrowBtn"
						onClick={() => sliderChange("next")}
					>
						<ReactSVG
							src="/img/icons/icon-arrow-right.svg"
							wrapper="span"
							className="icon"
						/>
					</button>
				</div>
			</SliderBtns>
			{items && (
				<SliderItems>
					<Swiper
						ref={sliderRef}
						slidesPerView={1.25}
						spaceBetween={15}
						breakpoints={{
							769: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
							1025: {
								slidesPerView: type === "ads" ? 3 : 4,
							},
						}}
					>
						{items.map((item, index) => {
							return (
								<SwiperSlide key={index}>
									{renderItem(item)}
								</SwiperSlide>
							);
						})}
					</Swiper>
				</SliderItems>
			)}
		</SliderWrapper>
	);
};

export default Slider;
